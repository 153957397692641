import { memo, ComponentPropsWithRef, forwardRef } from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

export type EmbeddedImageProps = ComponentPropsWithRef<'img'>;

type RefElement = HTMLImageElement;

const validateImageSrc = (
  src: string
): { isExternal: boolean; validSrc: string; error?: boolean } => {
  switch (true) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    case src.startsWith(process.env.NEXT_PUBLIC_STRAPI_API):
    case src.startsWith('/uploads/'): {
      return { isExternal: false, validSrc: `${process.env.NEXT_PUBLIC_STRAPI_API}${src}` };
    }

    case src.startsWith('https://res.cloudinary.com/studenten/image/upload/'): {
      return { isExternal: false, validSrc: src };
    }

    case src.startsWith('http'): {
      return { isExternal: true, validSrc: src };
    }

    default: {
      return { isExternal: true, validSrc: '', error: true };
    }
  }
};

const NativeImageWrapper = styled.div`
  margin: 24px auto;
  width: 100%;

  img {
    display: block;
    width: auto;

    margin: 0 auto;
  }

  ${up('md')} {
    max-height: 320px;

    img {
      max-height: 320px;
    }
  }
`;

const NextImageWrapper = styled.div`
  position: relative;
  display: block;
  margin: 24px 0;
  height: 100%;
  min-height: 290px;
  max-width: 100%;
`;

const EmbeddedImage = forwardRef<RefElement, EmbeddedImageProps>(
  ({ src = '', alt = '', ...props }, ref) => {
    const { error, isExternal, validSrc } = validateImageSrc(src);

    if (error) {
      return null;
    }

    if (isExternal) {
      return (
        <NativeImageWrapper>
          <img ref={ref} src={validSrc} alt={alt} {...props} />
        </NativeImageWrapper>
      );
    }

    return (
      <NextImageWrapper>
        <Image priority layout="fill" objectFit="contain" src={src} alt={alt} />
      </NextImageWrapper>
    );
  }
);

export default memo(EmbeddedImage);
