import React, { useEffect } from 'react';

export const useOutsideClick = (
  ref: React.RefObject<HTMLElement>,
  handler: (event: MouseEvent | TouchEvent) => void
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent): void => {
      const target = event.target as HTMLElement;
      if (!ref.current || ref.current.contains(target)) {
        return;
      }
      handler(event);
    };

    let supportsPassive = false;

    try {
      const opts = Object.defineProperty({}, 'passive', {
        get: function () {
          supportsPassive = true;
        },
      });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.addEventListener('testPassive', null, opts);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.removeEventListener('testPassive', null, opts);
      // eslint-disable-next-line no-empty
    } catch (e) {}

    document.addEventListener('mousedown', listener, supportsPassive ? { passive: true } : false);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};
