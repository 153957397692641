import React from 'react';
import Link from 'next/link';

import {
  StyledProfileImageWrapper,
  StyledProfilePlaceholderWrapper,
  StyledProfileWrapper,
  StyledProfileUsername,
  StyledTag,
  StyledTags,
  StyledTagsWrapper,
} from './InhurenStudentFeedCard.styled';
import { ImageWithFallback } from '../../../common';
import placeholder from '../../../../public/assets/placeholders/student-profile.svg';
import { parseImageUrl } from '../../../../utils/helpers';

export type IInhurenStudentFeedCardProps = {
  imgSrc?: string;
  tags?: string[];
  username?: string;
  href: string;
};

export const InhurenStudentFeedCard: React.FC<IInhurenStudentFeedCardProps> = ({
  imgSrc = '',
  tags,
  username,
  href,
}) => {
  const { src } = parseImageUrl(imgSrc);
  const [imgLoadFailed, setImgLoadFailed] = React.useState(!src);

  return (
    <Link href={href} passHref prefetch={false}>
      <StyledProfileImageWrapper>
        {imgLoadFailed ? (
          <StyledProfilePlaceholderWrapper>
            <ImageWithFallback
              priority
              width={288}
              height={384}
              objectFit="cover"
              layout="responsive"
              src={placeholder}
              fallbackSrc={placeholder}
              alt="Profile image placeholder"
            />
          </StyledProfilePlaceholderWrapper>
        ) : (
          <StyledProfileWrapper>
            <img
              src={src}
              alt={`${username} avatar`}
              onError={() => setImgLoadFailed(true)}
              loading="lazy"
            />
          </StyledProfileWrapper>
        )}
        <StyledTagsWrapper>
          <StyledProfileUsername>{username}</StyledProfileUsername>
          <StyledTags>
            {tags?.map((tag) => (
              <StyledTag key={tag}>{tag}</StyledTag>
            ))}
          </StyledTags>
        </StyledTagsWrapper>
      </StyledProfileImageWrapper>
    </Link>
  );
};
