import { FC, ComponentPropsWithoutRef, Children } from 'react';
import { MarkdownToJSX } from 'markdown-to-jsx';
import EmbeddedImage from './EmbeddedImage';
import {
  StyledMDWrapper,
  StyledMDWrapperBusiness,
  StyledParagraph,
  StyledParagraphBusiness
} from './MDComponents.styles';

/*
  Fixes an issue when parser place <img /> inside <p /> tag
  https://github.com/probablyup/markdown-to-jsx/issues/209#issuecomment-591609187
 */
const ParagraphComponent: FC<ComponentPropsWithoutRef<'p'>> = (props) => {
  const children = Children.toArray(props.children);

  const imageChild = children.some((child) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return child?.type === EmbeddedImage;
  });

  return imageChild ? <>{children}</> : <StyledParagraph {...props} />;
};

const ParagraphComponentBusiness: FC<ComponentPropsWithoutRef<'p'>> = (props) => {
  const children = Children.toArray(props.children);

  const imageChild = children.some((child) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return child?.type === EmbeddedImage;
  });

  return imageChild ? <>{children}</> : <StyledParagraphBusiness {...props} />;
};

export const richtextOptions: MarkdownToJSX.Options = {
  forceBlock: true,
  forceWrapper: true,
  wrapper: StyledMDWrapper,
  overrides: {
    img: {
      component: EmbeddedImage,
    },
    p: {
      component: ParagraphComponent,
    },
    code: {
      component: ParagraphComponent,
    },
  },
};

export const richtextOptionsBusiness: MarkdownToJSX.Options = {
  forceBlock: true,
  forceWrapper: true,
  wrapper: StyledMDWrapperBusiness,
  overrides: {
    img: {
      component: EmbeddedImage,
    },
    p: {
      component: ParagraphComponentBusiness,
    },
    code: {
      component: ParagraphComponentBusiness,
    },
  },
};
