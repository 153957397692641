import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';

const anchorMixin = css`
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;

  color: ${({ theme }) => theme.brand.orange};
  text-decoration-color: ${({ theme }) => theme.brand.orange};

  &:hover {
    cursor: pointer;
    text-decoration-color: ${({ theme }) => theme.brand.darkOrange};
    text-decoration: underline;
    color: ${({ theme }) => theme.brand.darkOrange};
  }

  &:visited {
    color: ${({ theme }) => theme.brand.darkOrange};
    text-decoration-color: ${({ theme }) => theme.brand.darkOrange};
  }
`;

const anchorMixinBusiness = css`
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;

  color: ${({ theme }) => theme.business.cyan};
  text-decoration-color: ${({ theme }) => theme.business.cyan};

  &:hover {
    cursor: pointer;
    text-decoration-color: ${({ theme }) => theme.business.lightCyan};
    text-decoration: underline;
    color: ${({ theme }) => theme.business.lightCyan};
  }

  &:visited {
    color: ${({ theme }) => theme.brand.darkBlue};
    text-decoration-color: ${({ theme }) => theme.brand.darkBlue};
  }
`;

const parentFontsMixin = css`
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
`;

export const StyledFigure = styled.figure`
  display: block;
  position: relative;
  max-width: 900px;
  height: auto;
  max-height: 300px;
  width: 100%;
  margin: 48px auto;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${up('md')} {
    margin: 64px auto;
  }

  ${anchorMixin}
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.system.black};
  text-decoration-color: ${({ theme }) => theme.system.black};

  &:hover {
    cursor: pointer;
    text-decoration-color: ${({ theme }) => theme.brand.blue};
    color: ${({ theme }) => theme.brand.blue};
  }

  &:visited {
    color: ${({ theme }) => theme.system.black};
    text-decoration-color: ${({ theme }) => theme.system.black};
  }
`;

export const StyledParagraph = styled.p`
  font-size: 1.6rem;
  line-height: 1.3;

  color: ${({ theme }) => theme.system.black};
`;

export const StyledParagraphBusiness = styled.p`
  font-size: 1.6rem;
  line-height: 1.3;

  color: ${({ theme }) => theme.business.cyan};
`;

export const StyledMDWrapper = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 1.6rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1.3;

    & + p {
      margin-top: 12px;
    }

    &:not(:first-child) {
      margin-top: 32px;
    }
  }

  p {
    a {
      ${anchorMixin}
    }

    & + p {
      margin-top: 24px;
    }
  }

  blockquote {
    display: block;
    margin: 16px 0;
    padding: 0 0 0 16px;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1.3;
    border-left: 4px solid ${({ theme }) => theme.brand.orange};

    &:first-child {
      margin-top: 0;
    }

    p {
      display: inline;
      background-color: ${({ theme }) => theme.brand.lightOrange};
      ${parentFontsMixin}
    }

    a {
      ${anchorMixin}
    }
  }

  hr {
    border-top: 1px solid ${({ theme }) => theme.brand.blue};
  }

  ul,
  ol {
    margin: 16px 0 20px;
    padding: 0;
    counter-reset: number;

    li {
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: 0;

      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }

      a {
        ${anchorMixin}
      }
    }
  }

  ul {
    margin-left: 1.5rem;
    list-style-type: disc;

    & li::marker {
      color: ${({ theme }) => theme.brand.orange};
    }
  }

  ol {
    margin-left: 1.6rem;
    list-style-type: decimal;

    li {
      padding-left: 4px;
    }

    & li::marker {
      font-weight: 700;

      color: ${({ theme }) => theme.system.black};
    }

    a::after {
      content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGcgY2xpcC1wYXRoPSJ1cmwoI2EpIj48cGF0aCBmaWxsPSIjRUY3RDAwIiBkPSJNMTAgNnYySDV2MTFoMTF2LTVoMnY2YTEuMDAwMSAxLjAwMDEgMCAwIDEtMSAxSDRhMS4wMDAyIDEuMDAwMiAwIDAgMS0xLTFWN2ExIDEgMCAwIDEgMS0xaDZabTExLTN2OGgtMlY2LjQxM2wtNy43OTMgNy43OTQtMS40MTQtMS40MTRMMTcuNTg1IDVIMTNWM2g4WiIvPjwvZz48ZGVmcz48Y2xpcFBhdGggaWQ9ImEiPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0wIDBoMjR2MjRIMHoiLz48L2NsaXBQYXRoPjwvZGVmcz48L3N2Zz4=);
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-left: 8px;
    }
  }

  & > u {
    display: block;
    max-width: 620px;
    margin: 0 auto;
  }

  & > * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const StyledMDWrapperBusiness = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 1.6rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1.3;

    & + p {
      margin-top: 12px;
    }

    &:not(:first-child) {
      margin-top: 32px;
    }
  }

  p {
    a {
      ${anchorMixinBusiness}
    }

    & + p {
      margin-top: 24px;
    }
  }

  blockquote {
    display: block;
    margin: 16px 0;
    padding: 0 0 0 16px;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1.3;
    border-left: 4px solid ${({ theme }) => theme.business.lightCyan};

    &:first-child {
      margin-top: 0;
    }

    p {
      display: inline;
      background-color: ${({ theme }) => theme.business.bgGray};
      ${parentFontsMixin}
    }

    a {
      ${anchorMixinBusiness}
    }
  }

  hr {
    border-top: 1px solid ${({ theme }) => theme.business.cyan};
  }

  ul,
  ol {
    margin: 16px 0 20px;
    padding: 0;
    counter-reset: number;

    li {
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: 0;

      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }

      a {
        ${anchorMixinBusiness}
      }
    }
  }

  ul {
    padding-left: 1.5rem;
    list-style-type: disc;

    & li::marker {
      color: ${({ theme }) => theme.business.lightCyan};
    }
  }

  ol {
    padding-left: 1.6rem;
    list-style-type: decimal;

    li {
      padding-left: 4px;
    }

    & li::marker {
      font-weight: 700;

      color: ${({ theme }) => theme.business.lightCyan};
    }
  }

  & > u {
    display: block;
    max-width: 620px;
    margin: 0 auto;
  }

  & > * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  dl {
    padding: 24px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.business.bgGray};
    ${parentFontsMixin}
  }

  dt {
    font-weight: 700;
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  }

  dt:before {
    content: '\\2714';
    display: inline-block;
    margin-right: 0.5rem;
    color: ${({ theme }) => theme.business.lightCyan};
  }

  dt + dd {
    margin-top: 8px;
  }

  dd + dd {
    margin-block: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
