import { memo, FC } from 'react';
import Markdown, { MarkdownToJSX } from 'markdown-to-jsx';
import { richtextOptions } from './Richtext.options';

export type RichtextProps = {
  [p: string]: any;
  children: string;
  options?: MarkdownToJSX.Options;
};

const Richtext: FC<RichtextProps> = ({ children, options = richtextOptions, ...props }) => (
  <Markdown options={options} {...props}>
    {children}
  </Markdown>
);

export default memo(Richtext);
