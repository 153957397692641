import styled from 'styled-components';

const boxShadow = '0 12px 28px 0 rgba(5, 28, 37, 0.19)';

export const StyledProfileImageWrapper = styled.a`
  text-decoration: none;
  position: relative;
  width: 100%;
  min-width: 0;
  max-width: 368px;
  min-height: 0;
  aspect-ratio: 368 / 480;

  margin: 0 auto;
  overflow: hidden;

  border-radius: 16px;

  background-color: #083344;
  box-shadow: ${boxShadow};

  &:hover {
    cursor: pointer;
  }
`;

export const StyledProfileUsername = styled.h5`
  margin-bottom: 12px;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.3125;
  text-align: center;
  color: ${({ theme }) => theme.system.white};
`;

export const StyledProfilePlaceholderWrapper = styled.div`
  position: relative;
  padding: 56px 51px;
`;

export const StyledProfileWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const StyledTagsWrapper = styled.div`
  position: absolute;
  padding: 8px;
  width: 100%;
  bottom: 0;
  min-height: 164px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  line-height: 1.3333;
  gap: 4px;

  max-height: calc(3 * (1.3333 * 12px + 2 * 8px) + 2 * 4px);
`;

export const StyledTag = styled.span`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.system.white};

  background-color: rgba(255, 255, 255, 0.2);
`;
